import i18n from 'i18next'

import appES from '../locales/es/app.json'
import commonES from '../locales/es/common.json'
import coursesES from '../locales/es/courses.json'
import facilitiesES from '../locales/es/facilities.json'
import featureES from '../locales/es/feature.json'
import heroES from '../locales/es/hero.json'
import newsletterES from '../locales/es/newsletter.json'

import appCA from '../locales/ca/app.json'
import commonCA from '../locales/ca/common.json'
import coursesCA from '../locales/ca/courses.json'
import facilitiesCA from '../locales/ca/facilities.json'
import featureCA from '../locales/ca/feature.json'
import heroCA from '../locales/ca/hero.json'
import newsletterCA from '../locales/ca/newsletter.json'

const resources = {
  es: {
    app: appES,
    common: commonES,
    courses: coursesES,
    facilities: facilitiesES,
  	feature: featureES,
    hero: heroES,
    newsletter: newsletterES
  },
  ca: {
    app: appCA,
    common: commonCA,
    courses: coursesCA,
    facilities: facilitiesCA,
  	feature: featureCA,
    hero: heroCA,
    newsletter: newsletterCA
  }
};

i18n
  .init({
  	resources,
  	lng: 'es',
    fallbackLng: 'es',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    }
  });


export default i18n;